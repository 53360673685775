<template>
  <LiefengContent :isBack="$route.query.isLineHome" @backClick="$router.push('/linehomepage')">
    <template v-slot:title> 底册数据管理 
      <span @click.stop="changeEye">
          <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
      </span>
       <!-- <Button type="error" v-if="$route.query.isLineHome" @click="$router.push('/linehomepage')">返回</Button> -->
    </template>
    <!-- <template v-slot:toolsbarLeft>
      <LiefengTree
        ref="mychild"
        :fixedmenu="true"
        @handleData="getList"
        style="width: 200px"
        treeID="mychild"
        fixedContentID="contentarea"
      ></LiefengTree>
    </template> -->
    <template v-slot:toolsbarRight>
      <Form  :label-colon="true" :inline="true" class="search">
        <FormItem>
          <Cascader transfer @on-change="changeCasder" :load-data="loadData" change-on-select style="margin-right: 10px; width: 200px" :data="CascaderList" v-model="CascaderId" placeholder="请选择区/街道/社区"></Cascader>
        </FormItem>
        <FormItem>
           <Input
          :maxlength="20"
          style="margin-right: 10px; width: 100px"
          placeholder="按姓名查询"
          v-model.trim="selectForm.name"
        />
        </FormItem>
        <FormItem>
          <Select
            transfer
            v-model="selectForm.activeStatus"
            placeholder="按激活状态查询"
            style="margin-right: 10px;width: 100px"
          >
            <Option value="0">全部</Option>
            <Option value="1">未激活</Option>
            <Option value="2">已激活</Option>
          </Select>
      </FormItem>
        <FormItem>
           <Select
            transfer
            v-model="selectForm.syncHomeStatus"
            placeholder="同步状态查询"
            style="width: 100px">
              <Option value="">全部</Option>
              <Option value="1">失败</Option>
              <Option value="2">成功</Option>
          </Select>
        </FormItem>
        <Button style="margin-right:10px" @click="inputSearch" type="primary" icon="ios-search"
        >查询</Button
          >
          <Button style="margin-right:10px"  type="success" @click="reset" icon="ios-refresh">重置</Button>
          <!-- <Button type="primary" @click="addModal" icon="ios-add">新增</Button> -->
          <!-- <Button style="margin-right:10px"  type="primary" @click="syncOnlineHome" :disabled="isDieabled">同步到网上家园</Button> -->
          <!-- <Button type="primary" @click="openMessage">发短信</Button> -->
          <Button style="margin-right:10px"  type="primary" @click="openRecord">短信</Button>
          <!-- <Button style="margin-right:10px"  type="error" @click="importExcel" icon="ios-add-circle-outline"
            >导入</Button
          > -->
          <Button
            style="margin-right:10px" 
            @click="exportExcelTable"
            type="warning"
            icon="ios-cloud-download-outline"
            >导出</Button
          >
          <!-- <Button type="warning" icon="ios-mail" @click="msgModal = true"
            >发消息</Button
          > -->
          <Button type="error" v-if="isDeleteAll" @click="deleteAllList">清除底册数据</Button>
          <Icon
            type="md-more"
            @click="selectQuery = true"
            style="font-size: 24px; line-height: 32px; cursor: pointer"
            title="更多查询条件"
          />
      </Form>
      
     
      
     
      

      <Drawer
        title="查询条件"
        v-model="selectQuery"
        width="320"
        :styles="{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: '53px',
          position: 'static',
        }"
      >
        <Form :model="selectForm" :label-colon="true">
          <FormItem label="姓名">
            <Input
              :maxlength="20"
              placeholder="按姓名查询"
              v-model.trim="selectForm.name"
            />
          </FormItem>
          <FormItem label="联系电话">
            <Input
              :maxlength="20"
              v-model.trim="selectForm.phone"
              placeholder="请输入联系电话"
            ></Input>
          </FormItem>
          <FormItem label="现居地址">
            <Input
              :maxlength="20"
              v-model.trim="selectForm.addr"
              placeholder="请输入现居地址"
              style="width: 100%"
            />
          </FormItem>
          <FormItem label="户籍地址">
            <Input
              :maxlength="20"
              v-model.trim="selectForm.homeAddr"
              placeholder="请输入户籍地址"
              style="width: 100%"
            />
          </FormItem>
          <FormItem label="激活状态">
            <Select
        v-model="selectForm.activeStatus"
        placeholder="按激活状态查询"
      >
        <Option value="0">全部</Option>
        <Option value="1">未激活</Option>
        <Option value="2">已激活</Option>
      </Select>
          </FormItem>
          <FormItem label="激活时间">
            <DatePicker type="daterange" transfer v-model="selectForm.activeTime" placeholder="请选择激活时间" style="width: 100%"></DatePicker>
          </FormItem>
        </Form>
        <div class="demo-drawer-footer">
          <Button style="margin-right: 8px" @click="selectQuery = false"
            >取消</Button
          >
          <Button type="primary" @click="inputSearch">查询</Button>
        </div>
      </Drawer>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        height="200"
        :loading="loading"
        :fixTable="true"
        :pagesizeOpts="[20, 30, 50, 100]"
        :curPage="pageNum"
        :total="total"
        :page-size="pageSize"
        @hadlePageSize="hadlePageSize"
        @tableSelect="tableSelect"
        
      ></LiefengTable>
      <!-- 新增/修改弹窗 -->
      <LiefengModal
        :title="modalTitle"
        :fullscreen="true"
        :value="modalStatus"
        @input="modalData"
      >
        <template v-slot:contentarea>
          <Form
            ref="formValidate"
            class="modal"
            :model="formValidate"
            :rules="ruleValidate"
            :label-width="140"
          >
            <FormItem label="姓名" prop="name">
              <Input
                :maxlength="20"
                v-model.trim="formValidate.name"
                placeholder="请输入姓名"
              ></Input>
            </FormItem>
            <FormItem label="曾用名">
              <Input
                :maxlength="20"
                v-model.trim="formValidate.alias"
                placeholder="请输入曾用名"
              ></Input>
            </FormItem>
            <FormItem label="身份证类型" prop="idNumType">
              <Select
                v-model="formValidate.idNumType"
                placeholder="请选择身份证类型"
              >
                <Option
                  :value="item.value"
                  v-for="(item, index) in idNumTypeList"
                  :key="index"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="证件号码" prop="idNum">
              <Input
                :maxlength="20"
                v-model.trim="formValidate.idNum"
                placeholder="请输入证件号码"
              ></Input>
            </FormItem>
            <FormItem label="民族">
              <Input
                :maxlength="20"
                v-model.trim="formValidate.national"
                placeholder="请输入名族"
              ></Input>
            </FormItem>
            <FormItem label="性别" prop="sex">
              <Select v-model="formValidate.sex" placeholder="请选择性别">
                <Option value="1">男</Option>
                <Option value="2">女</Option>
              </Select>
            </FormItem>
            <FormItem label="出生日期">
              <DatePicker
                type="date"
                placeholder="请输入出生日期"
                v-model="formValidate.birthday"
              ></DatePicker>
            </FormItem>
            <FormItem label="现住地详细地址" prop="addr">
              <Input
                :maxlength="20"
                v-model.trim="formValidate.addr"
                placeholder="请输入现住地详细地址"
              ></Input>
            </FormItem>
            <FormItem label="街巷" prop="street">
              <Input
                :maxlength="20"
                v-model.trim="formValidate.street"
                placeholder="请输入街巷"
              ></Input>
            </FormItem>
            <FormItem label="小区名称">
              <Input
                :maxlength="20"
                v-model.trim="formValidate.projectName"
                placeholder="请输入小区名称"
              ></Input>
            </FormItem>
            <FormItem label="楼栋/门牌号码" prop="building">
              <Input
                :maxlength="20"
                v-model.trim="formValidate.building"
                placeholder="请输入楼栋/门牌号码"
              ></Input>
            </FormItem>
            <FormItem label="房号" prop="houseNum">
              <Input
                :maxlength="20"
                v-model.trim="formValidate.houseNum"
                placeholder="请输入房号"
              ></Input>
            </FormItem>
            <FormItem label="住房编号" prop="houseCode">
              <Input
                :maxlength="20"
                v-model.number.trim="formValidate.houseCode"
                placeholder="请输入住房编号"
              ></Input>
            </FormItem>
            <FormItem label="住房内户主数量" prop="ownerNum">
              <Input
                :maxlength="20"
                v-model.number.trim="formValidate.ownerNum"
                placeholder="请输入住房内户主数量"
              ></Input>
            </FormItem>
            <FormItem label="户籍地行政区划代码">
              <Input
                :maxlength="20"
                v-model.trim="formValidate.homeOrgCode"
                placeholder="请输入户籍地行政区划代码"
              ></Input>
            </FormItem>
            <FormItem label="户籍地行政区划名称">
              <Input
                :maxlength="20"
                v-model.trim="formValidate.homeOrgName"
                placeholder="请输入户籍地行政区划名称"
              ></Input>
            </FormItem>
            <FormItem label="户籍地详细地址">
              <Input
                :maxlength="20"
                v-model.trim="formValidate.homeAddr"
                placeholder="请输入户籍地详细地址"
              ></Input>
            </FormItem>
            <FormItem label="教育情况">
              <Select v-model="formValidate.edu" placeholder="请选择教育情况">
                <Option value="小学">小学</Option>
                <Option value="初中">初中</Option>
                <Option value="高中">高中</Option>
                <Option value="大学">大学</Option>
                <Option value="硕士">硕士</Option>
                <Option value="博士">博士</Option>
              </Select>
            </FormItem>
            <FormItem label="固定电话">
              <Input
                :maxlength="20"
                v-model.number.trim="formValidate.tel"
                placeholder="请输入固定电话"
              ></Input>
            </FormItem>
            <FormItem>
              <span slot="label" class="phone">手机号码:</span>
              <div
                v-for="(item, index) in phone"
                :key="index"
                style="display: inline-block; width: 31%"
              >
                <Input
                  :maxlength="20"
                  v-model.trim="phone[index]"
                  placeholder="请输入手机号码"
                ></Input>
              </div>
              <Button
                type="dashed"
                @click="handleAdd"
                icon="md-add"
                style="width: 7%"
              ></Button>
            </FormItem>
            <FormItem label="职业">
              <Input
                :maxlength="20"
                v-model.trim="formValidate.profession"
                placeholder="请输入职业"
              ></Input>
            </FormItem>
            <FormItem label="流入日期">
              <DatePicker
                type="date"
                placeholder="请输入流入日期"
                v-model="formValidate.intoDate"
              ></DatePicker>
            </FormItem>
            <FormItem label="流出日期">
              <DatePicker
                type="date"
                placeholder="请输入流出日期"
                v-model="formValidate.outDate"
              ></DatePicker>
            </FormItem>
            <FormItem label="工作单位">
              <Input
                :maxlength="20"
                v-model.trim="formValidate.workUnit"
                placeholder="请输入工作单位"
              ></Input>
            </FormItem>
            <FormItem label="婚姻状况">
              <Select
                v-model="formValidate.marital"
                placeholder="请选择婚姻状况"
              >
                <Option value="未婚">未婚</Option>
                <Option value="已婚">已婚</Option>
              </Select>
            </FormItem>
            <FormItem label="死亡日期">
              <DatePicker
                type="date"
                placeholder="请输入死亡日期"
                v-model="formValidate.deathDate"
              ></DatePicker>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" @click="cancel">取消</Button>
          <Button style="margin-left: 10px" type="primary" @click="save"
            >确定</Button
          >
        </template>
      </LiefengModal>
      <!-- 导入excel弹窗 -->
      <LiefengModal
        title="导入底册数据"
        width="40%"
        :value="excelStatus"
        @input="excelModalData"
      >
        <template v-slot:contentarea>
          <!-- <Form
            :model="formValidate"
            :rules="ruleForm"
            :label-colon="true"
            :label-width="100"
          >
            <FormItem prop="marital" label="所属社区">
              <Select
                v-model="formValidate.marital"
                placeholder="请选择社区"
                filterable
                @on-change="selectCommunity"
              >
                <Option
                  v-for="(item, index) in communityList"
                  :key="index"
                  :value="item.code"
                  >{{ item.fullName }}</Option
                >
              </Select>
            </FormItem>
          </Form> -->
          <Divider orientation="left">数据上传</Divider>
          <Upload
            ref="excelUpload"
            type="drag"
            :action="action"
            name="file"
            :show-upload-list="false"
            :before-upload="beforeExcelUpload"
            :on-success="myExcel"
            :on-error="errorUpload"
            :data="uploadData"
            :format="['xls', 'xlsx', 'xlsb', 'xlsm', 'xlst']"
            :on-format-error="uploadFormatError"
            :headers="headers"
          >
            <div style="padding: 20px 0; text-align: center; width: 100%">
              <Icon
                type="ios-cloud-upload"
                size="52"
                style="color: #3399ff"
              ></Icon>
              <p>点击上传，或将文件拖拽到此处</p>
            </div>
          </Upload>
          <div v-if="excelFile != null">
            当前选择的文件名称:{{ excelFile.name }}
          </div>
          <!-- <div v-html="errorContent"></div> -->
          <div style="text-align: center; margin: 20px 0">
            <Button type="primary" style="margin-right: 20px"
              ><a
                href="./excelfiles/basebook.xlsx"
                download="底册导入模板.xlsx"
                style="color: #fff"
                >模板下载</a
              ></Button
            >
            <Button type="info" @click="submitExcel">确认导入</Button>
          </div>
        </template>
      </LiefengModal>

      <!-- 发消息 -->
      <LiefengModal
        :value="msgModal"
        title="发消息"
        width="800px"
        height="calc(100vh - 200px)"
        @input="sentMsgModal"
      >
        <template v-slot:contentarea>
          <Form
            :model="msgform"
            :label-width="80"
            ref="msgform"
            :rules="msgformValidate"
          >
            <formItem
              ><Button type="primary" @click="selectModal = true"
                >选择接收人</Button
              ></formItem
            >
            <FormItem label="接收人" prop="ids"
              ><Input
                disabled
                v-model.trim="selectName"
                type="textarea"
                :rows="2"
                placeholder="请选择接收人"
              ></Input
            ></FormItem>
            <FormItem label="内容" prop="content"
              ><Input
                v-model.trim="msgform.content"
                type="textarea"
                :maxlength="800"
                show-word-limit
                :rows="8"
                placeholder="请输入内容"
              ></Input
            ></FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" @click="sendRest" style="margin-right: 10px"
            >清空</Button
          >
          <Button type="primary" @click="sendOK('msgform')">确定</Button>
        </template>
      </LiefengModal>
      <!--选择接收人-->
      <LiefengModal
        :value="selectModal"
        title="选择接收人"
        :fullscreen="true"
        @input="selectStatus"
        id="choose"
      >
        <template v-slot:contentarea>
          <SelectUser
            ref="selchildren"
            @handleSelect="selectData"
            @communityCode="communityCodeFn"
          ></SelectUser>
        </template>
        <template v-slot:toolsbar>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="saveUser"
            >保存</Button
          >
        </template>
      </LiefengModal>



      <!--发短信-->
      <LiefengModal :value="messageStatus"  @input="messageInput" title="发短信" :fullscreen="false" width="800px" height="540px" >
          <template v-slot:contentarea>
              <Message @SmsUser="SmsUser" ref="message" :isShow="showNumber" v-if="showMessage"></Message>
          </template>
          <template v-slot:toolsbar>
             <Button type="info" @click="messageBack" style="margin-right: 10px" >取消</Button>
            <Button style="margin: 0 5px 0 13px" :disabled="sendDisabled" type="primary" @click="messageSend">确定发送</Button>
          </template>
      </LiefengModal>
      <!-- 短信开通提醒界面 -->
      <LiefengModal 
        title='短信开通提醒'
        :value="openTips"
        @input="changeOpenTips"
        width="500px"
        height="200px"
        >
        <template v-slot:contentarea>
          <p style="fontSize:16px">{{orgName}}还未开通短信服务，通过随约系统发送短信，会产生短信费最高0.07元/条，是否提交开通此项服务的申请？如有疑问请联系社区实施专管员。</p>
        </template>
        <template v-slot:toolsbar>
             <Button type="primary" style="marginRight:10px" :loading='opLoading' @click="goOpen">我要申请开通</Button>
            <Button @click="notOpen">暂不开通</Button>
          </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/basebookindex')
import LiefengContent from "@/components/LiefengContent3";
import LiefengTree from "@/components/LiefengTree";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import SelectUser from "@/views/basebook/childrens/selectUser";
import { format } from "@/utils/formatTime.js";
import { idcard, validateMobile } from "@/utils/validate.js";
import Message from './childrens/message.vue'
export default {
  watch: {
    selectQuery(newV) {
      if (!newV) {
        this.selectForm.name = '';
            this.selectForm.activeStatus = '0';
            this.selectForm.phone = '';
            this.selectForm.addr = '';
            this.selectForm.homeAddr = '';
            this.selectForm.activeTime = [];
            this.selectForm.syncHomeStatus = ''
      }
    },
  },
  data() {
    return {
      hasSensitive:true,
      openTips:false,
      orgName:'',

      headers: {openId: window.sessionStorage.getItem('openId'),Authorization:window.sessionStorage.getItem('accessToken')},
      // 列表搜索
      selectForm: {
        name: "",
        activeStatus: "0",
        activeTime: [],
        phone: '',
        addr: '',
        homeAddr: '',
        syncHomeStatus:''
      },
      selectQuery: false,
      // 发信息
      msgModal: false,
      nocommunityCode: "",
      communityCode: "",
      nocommunityCode: "",
      msgformValidate: {
        ids: [
          {
            required: true,
            message: "接收人不能为空",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "内容不能为空",
            trigger: "blur",
          },
        ],
      },
      total: 0,
      treeData: [],
      msgModal: false,
      selectModal: false,
      msgform: {
        oemCode: parent.vue.oemInfo.oemCode,
        ids: "",
        content: "",
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
      },
      // talbeColumns: [
      //   {
      //     type: "selection",
      //     width: 60,
      //     align: "center",
      //   },
      //   {
      //     title: "住房编号",
      //     key: "familyNo",
      //     width: 100,
      //     align: "center",
      //   },
      //   {
      //     title: "姓名",
      //     key: "name",
      //     width: 100,
      //     align: "center",
      //   },
      //   {
      //     title: "性别",
      //     key: "sexLabel",
      //     width: 80,
      //     align: "center",
      //   },
      //   {
      //     title: "联系电话",
      //     key: "phone",
      //     width: 130,
      //     align: "center",
      //   },
      //   {
      //     title: "身份类型",
      //     key: "userTypeLabel",
      //     width: 120,
      //     align: "center",
      //   },
      //   {
      //     title: "入驻时间",
      //     key: "gmtCreate",
      //     width: 180,
      //     align: "center",
      //   },
      //   {
      //     title: "区",
      //     key: "zoneName",
      //     width: 180,
      //     align: "center",
      //   },
      //   {
      //     title: "街道",
      //     key: "streeName",
      //     width: 180,
      //     align: "center",
      //   },
      //   {
      //     title: "社区",
      //     key: "projectName",
      //     width: 180,
      //     align: "center",
      //   },
      //   {
      //      title: "户籍地址",
      //       key: "homeAddr",
      //       width: 180,
      //       align: "center",
      //   },
      //   {
      //     title: "所在详细地址",
      //     key: "detailAddr",
      //     minWidth: 150,
      //     align: "center",
      //     render: (h, params) => {
      //       return h("div", [
      //         h(
      //           "span",
      //           {
      //             style: {
      //               display: "inline-block",
      //               width: "100%",
      //               overflow: "hidden",
      //               textOverflow: "ellipsis",
      //               whiteSpace: "nowrap",
      //             },
      //             domProps: {
      //               title: params.row.roadName + params.row.detailAddr,
      //             },
      //             on: {
      //               click: (e) => {
      //                 e.stopPropagation();
      //               },
      //             },
      //           },
      //           params.row.roadName + params.row.detailAddr
      //         ),
      //       ]);
      //     },
      //   },

      //   {
      //     title: "是否家庭联络人",
      //     key: "contactLabel",
      //     width: 140,
      //     align: "center",
      //   },
      //   {
      //     title: "操作",
      //     slot: "action",
      //     align: "center",
      //     width: 80,
      //     render: (h, params) => {
      //       return h("div", [
      //         h(
      //           "Button",
      //           {
      //             props: {
      //               type: "info",
      //               size: "small",
      //             },
      //             on: {
      //               click: () => {
      //                 this.remove(params.row);
      //               },
      //             },
      //           },
      //           "删除"
      //         ),
      //       ]);
      //     },
      //   },
      // ],
      tableData: [],
      selection: [],
      //导入参数
      uploadData: {},
      errorContent: "",
      action: "",
      ruleForm: {
        marital: [{ required: true, message: "请选择社区", trigger: "change" }],
      },
      //弹窗验证数据
      ruleValidate: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        idNumType: [
          { required: true, message: "请选择身份证类型", trigger: "change" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        idNum: [{ required: true, validator: idcard, trigger: "blur" }],
        ownerNum: [
          {
            min: 1,
            type: "number",
            message: "请输入合适的户主数量",
            trigger: "blur",
          },
          {
            max: 99,
            type: "number",
            message: "请输入合适的户主数量",
            trigger: "blur",
          },
        ],
        addr: [
          { required: true, message: "请输入现住地详细地址", trigger: "blur" },
        ],
        street: [{ required: true, message: "请输入街巷", trigger: "blur" }],
        building: [
          { required: true, message: "请输入楼栋/门牌号码", trigger: "blur" },
        ],
        houseNum: [{ required: true, message: "请输入房号", trigger: "blur" }],
        houseCode: [
          {
            max: 999999,
            type: "number",
            message: "房号最多只能6位数",
            trigger: "blur",
          },
          {
            min: 1,
            type: "number",
            message: "请输入正确的房号",
            trigger: "blur",
          },
        ],
        phone: [{ required: true, validator: validateMobile, trigger: "blur" }],
      },
      //弹窗数据
      formValidate: {
        orgCode: "",
        orgName: "",
        houseCode: "",
        ownerNum: "",
        name: "",
        alias: "",
        idNumType: "",
        idNum: "",
        national: "",
        sex: "0",
        birthday: "",
        addr: "",
        street: "",
        projectName: "",
        building: "",
        houseNum: "",
        homeOrgCode: "",
        homeOrgName: "",
        homeAddr: "",
        edu: "",
        tel: "",
        profession: "",
        intoDate: "",
        outDate: "",
        workUnit: "",
        marital: "",
        deathDate: "",
        oemCode: "zjsm",
        id: "",
        phone: "",
      },
      phone: [""],
      //身份证类型
      idNumTypeList: [
        {
          value: "1",
          name: "内地身份证",
        },
        {
          value: "2",
          name: "港澳台身份证",
        },
        {
          value: "3",
          name: "国外护照",
        },
        {
          value: "4",
          name: "其他",
        },
      ],
      talbeColumns: [
        {
          type:"selection",
          width:80,
          align:'center'
        },
        {
          title: "区",
          key: "zoneName",
          width: 180,
          align: "center",
        },
        {
          title: "街道",
          key: "streetName",
          width: 180,
          align: "center",
        },
        {
          title: "社区",
          key: "orgName",
          width: 180,
          align: "center",
        },
        {
          title: "姓名",
          key: "name",
          width: 100,
          align: "center",
        },
        {
          title: "性别",
          key: "sex",
          width: 100,
          align: "center",
        },
        {
          title: "联系电话",
          key: "phone",
          width: 130,
          align: "center",
        },
        {
          title: "出生日期",
          key: "birthday",
          align: "center",
          width: 120,
        },
        {
          title: "房号编码",
          key: "houseCode",
          align: "center",
          width:200,
        },
        {
          title: "现居地址",
          key: "addr",
          minWidth: 180,
          align: "center",
        },
         
        {
           title: "户籍地址",
            key: "homeAddr",
            width: 180,
            align: "center",
        },
        {
           title: "同步状态",
            key: "syncHomeStatus",
            width: 180,
            align: "center",
            render:(h,params) =>{
              return h('div',{},params.row.syncHomeStatus && params.row.syncHomeStatus != '' ? params.row.syncHomeStatus == '1' ? '失败' : params.row.syncHomeStatus == '2' ? '成功' :'' :'')
            }
        },
        {
          title: "激活状态",
          key: "activeStatus",
          width: 120,
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 160,
          fixed:'right',
          render: (h, params) => {
            return h("div", [
              // h(
              //   "Button",
              //   {
              //     props: {
              //       type: "info",
              //       size: "small",
              //     },
              //     on: {
              //       click: () => {
              //         this.changeRow(params.row);
              //       },
              //     },
              //   },
              //   "修改"
              // ),
              h(
                "Button",
                {
                  props: {
                    type: "info",
                    size: "small",
                  },
                  style: {
                    marginLeft: "14px",
                  },
                  on: {
                    click: () => {
                      this.delRow(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      loading: false,
      //当前页
      pageNum: 1,
      total: 0,
      pageSize: 20,
      //搜索框
      selectName: "",
      //菜单树社区编码
      orgCode: "",
      orgName: "",
      streetCode: "",
      regionType: "",
      region: "",
      build: "",
      //新增/修改弹窗状态
      modalStatus: false,
      modalTitle: "",
      //判断新增还是修改,true代表新增
      addChangeStatus: true,
      //excel导入弹窗
      excelStatus: false,
      //excel导入弹窗  社区数据
      communityList: [],
      //excel文件数据
      excelFile: null,

      CascaderList:[],
      CascaderId:[],



      // 发消息
      messageStatus:false,
      showNumber:0,
      sendDisabled:false,

      selectTableList:[],
      isDieabled:false,

      isNum:0,
      showMessage:false,
      opLoading:false,


      // 根据按钮显示权限删除按钮
      isDeleteAll:false
    };
  },
  methods: {
    // 按机构删除按钮
    deleteAllList(){
      if(this.CascaderId.length == 0){
        this.$Message.warning({
          content:'请选择到社区删除',
          background:true
        })
        return
      }
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在删除当前所选范围数据，是否继续？</p>",
          onOk: async () => {
            let res = await this.$post('/syaa/pc/sy/user/userOriginal/deleteOriginalOrg',{
              orgCode:this.CascaderId[this.CascaderId.length - 1]
            })
            console.log('res',res);
              if(res && res.code == 200){
                this.$Message.success({
                  content:'删除成功',
                  background:true
                })
                this.pageNum = 1
                this.pageSize = 20
                this.hadlePageSize({
                  pageSize: this.pageSize,
                  page: this.pageNum,
                });
                return
              }else{
                this.$Message.error({
                  content:res.desc,
                  background:true
                })
                return
              }
            },
        });
    },
    // 同步按钮
    syncOnlineHome(){
      if(this.selectTableList.length == 0){
        this.$Message.warning({
          content:'请先勾选表格数据',
          background:true
        })
        return
      }
      this.syncHome()
    },
    // 表格勾选事件
    tableSelect(val){
      this.selectTableList = []
      if(val && val.length != 0){
        val.map(item =>{
          this.selectTableList.push(item.id)
        })
      }
    },
    // 开通短信提示
    changeOpenTips(status){
      this.openTips = status
    },
    goOpen(){
      this.opLoading = true
      this.$post('/syaa/pc/sy/smsChannel/submitChannel',{
          orgCode:parent.vue.loginInfo.userinfo.orgCode,
          custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
          channelId:'1'
      },
      { "Content-Type": "application/json" }).then( res => {
          if(res.code == 200){
              this.$Message.success({
                  content:'申请发送成功',
                  background:true
              })
              
          }else{
              this.$Message.warning({
                  content:res.desc,
                  background:true
              })
              
          }
      })
      this.opLoading = false
      this.changeOpenTips(false)
    },
    notOpen(){
      this.changeOpenTips(false)
    },

    // 发信息
    SmsUser(status){
      this.sendDisabled = status
    },
    // 点击短信记录按钮
    openRecord(){
      this.$router.push('/smsrecordindex?name=basebookindex')
    },
    //发消息弹窗状态改变
    sentMsgModal(status) {
      this.msgModal = status;
    },
    //选择接收人弹窗状态改变
    selectStatus(status) {
      this.selectModal = status;
    },
    communityCodeFn(data) {
      this.nocommunityCode = data;
    },
    selectData(data) {
      let temp = [];
      let tempId = [];
      data.forEach((item) => {
        tempId.push(item.id);

        temp.push(item.title);
      });
      this.selectName = temp.join(",");
      this.selectId = tempId;
    },

    saveUser() {
      this.communityCode = this.nocommunityCode;
      this.selectModal = false;
      this.selectId = Array.from(new Set(this.selectId)); // 去重

      this.msgform.ids = this.selectId.join(",");
    },
    sendOK(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$get("/syaa/pc/sy/user/userOriginal/pushMessage", {
            ...this.msgform,
          })
            .then((res) => {
              if (res.code === "200") {
                this.$Message.success("发送成功");
                this.msgModal = false;
                this.msgform.ids = "";
                this.msgform.content = "";
                this.selectName = "";
                this.selectId = [];
                this.$refs.selchildren.removeAll();
              } else {
                this.$Message.error(res.desc);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    sendRest() {
      this.msgform.content = "";
    },
    //导出excel
    exportExcelTable() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "姓名",
            "性别",
            "联系电话",
            "出生日期",
            "现居地址",
            "户籍地址",
            "激活状态",
          ];
          let filterVal = [
            "name",
            "sex",
            "phone",
            "birthday",
            "addr",
            "homeAddr",
            "activeStatus",
          ];
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "底册列表"
          );
        },
      });
    },
    //手机号码新增
    handleAdd() {
      this.phone.push("");
    },
    //新增/修改弹窗取消
    cancel() {
      this.modalData(false);
    },
    //新增/修改弹窗确定
    save() {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          let houseCode = this.formValidate.houseCode.toString();
          let ownerNum = this.formValidate.ownerNum.toString();
          while (houseCode.length < 6 && houseCode.length > 0) {
            houseCode = "0" + houseCode;
          }
          while (ownerNum.length < 2 && houseCode.length > 0) {
            ownerNum = "0" + ownerNum;
          }
          let phone = [];
          this.phone.map((item) => {
            if (item != "") {
              phone.push(item);
            }
          });
          if (phone.length == 0) {
            this.$Message.error({
              background: true,
              content: "至少输入一个手机号码",
            });
            return;
          }
          if (this.addChangeStatus) {
            //新增保存
            this.$post("/syaa/pc/sy/user/userOriginal/saveUserOriginal", {
              ...this.formValidate,
              ownerNum: ownerNum,
              houseCode: houseCode,
              birthday: this.formValidate.birthday
                ? format(this.formValidate.birthday, "yyyy-MM-dd")
                : "",
              deathDate: this.formValidate.deathDate
                ? format(this.formValidate.deathDate, "yyyy-MM-dd")
                : "",
              intoDate: this.formValidate.intoDate
                ? format(this.formValidate.intoDate, "yyyyMMdd")
                : "",
              outDate: this.formValidate.outDate
                ? format(this.formValidate.outDate, "yyyyMMdd")
                : "",
              orgCode: this.orgCode,
              orgName: this.orgName,
              phone: phone.join(","),
            })
              .then((res) => {
                if (res.code == 200) {
                  this.hadlePageSize({
                    pageSize: this.pageSize,
                    page: this.pageNum,
                  });
                  //成功后关闭弹窗
                  this.modalData(false);
                  this.$Message["success"]({
                    background: true,
                    content: "新增成功！",
                  });
                } else {
                  this.$Message["error"]({
                    background: true,
                    content: "新增失败！",
                  });
                }
              })
              .catch((err) => {
                this.$Message["error"]({
                  background: true,
                  content: "新增失败！",
                });
              });
          } else {
            //修改保存
            this.$post("/syaa/pc/sy/user/userOriginal/updateUserOriginal", {
              ...this.formValidate,
              birthday: this.formValidate.birthday
                ? format(this.formValidate.birthday, "yyyy-MM-dd")
                : "",
              deathDate: this.formValidate.deathDate
                ? format(this.formValidate.deathDate, "yyyy-MM-dd")
                : "",
              intoDate: this.formValidate.intoDate
                ? format(this.formValidate.intoDate, "yyyyMMdd")
                : "",
              outDate: this.formValidate.outDate
                ? format(this.formValidate.outDate, "yyyyMMdd")
                : "",
              phone: phone.join(","),
            })
              .then((res) => {
                if (res.code == 200) {
                  this.hadlePageSize({
                    pageSize: this.pageSize,
                    page: this.pageNum,
                  });
                  //成功后关闭弹窗
                  this.modalData(false);
                  this.$Message["success"]({
                    background: true,
                    content: "修改成功！",
                  });
                } else {
                  this.$Message["error"]({
                    background: true,
                    content: "修改失败！",
                  });
                }
              })
              .catch((err) => {
                this.$Message["error"]({
                  background: true,
                  content: "修改失败！",
                });
              });
          }
        }
      });
    },
    //新增addModal
    addModal() {
      if(this.CascaderId.length != 0 && this.CascaderId.length != 3){
          this.$Message.warning({
            content:'请选择到社区进行新增',
            background:true
          })
          return 
      }
      //标识当前为新增
      this.addChangeStatus = true;
      this.modalTitle = "新增底册";
      //清除表单验证错误信息
      this.$refs.formValidate.resetFields();
      for (let k in this.formValidate) {
        //清空数据
        this.formValidate[k] = "";
        if (k == "oemCode") {
          this.formValidate[k] = "zjsm";
        }
      }
      this.phone = [""];
      this.modalStatus = true;
    },
    //删除
    delRow(row) {
      this.$Modal.confirm({
        title: "温馨提示",
        content: "<p>确定删除该信息？</p>",
        onOk: () => {
          this.$post("/syaa/pc/sy/user/userOriginal/deleteOriginal", {
            id: row.id,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "删除成功！",
                });
                this.hadlePageSize({
                  pageSize: this.pageSize,
                  page: this.pageNum,
                });
              } else {
                this.$Message.error({
                  background: true,
                  content: "删除失败！",
                });
              }
            })
            .catch((err) => {
              this.$Message.error({
                background: true,
                content: "删除失败！",
              });
            });
        },
      });
    },
    //修改
    changeRow(row) {
      //标识当前为修改
      this.addChangeStatus = false;
      this.modalTitle = "修改底册";
      //清除表单验证错误信息
      this.$refs.formValidate.resetFields();

      this.$get("/syaa/pc/sy/user/userOriginal/selectOriginalById", {
        id: row.id,
      })
        .then((res) => {
          for (let k in res.data) {
            //给formValidate赋值
            if (this.formValidate.hasOwnProperty(k)) {
              this.formValidate[k] = res.data[k];
              if (k == "birthday" && res.data.birthday) {
                this.formValidate[k] = new Date(res.data[k]);
              } else if (k == "deathDate" && res.data.deathDate) {
                this.formValidate[k] = new Date(res.data[k]);
              } else if (k == "houseCode" && res.data[k] != "" && res.data[k] != null) {
                this.formValidate[k] = Number(res.data[k]);
              } else if (k == "ownerNum" && res.data[k] != ""  && res.data[k] != null) {
                this.formValidate[k] = Number(res.data[k]);
              } else if (k == "phone") {
                this.phone = res.data[k].split(",");
              }
            }
          }
        })
        .catch((err) => {
          this.$Message["error"]({
            background: true,
            content: "获取修改数据失败！",
          });
        });
      this.modalStatus = true;
    },
    //修改新增弹窗状态
    modalData(status) {
      this.modalStatus = status;
    },
    //修改excel导入弹窗状态
    excelModalData(status) {
      this.excelStatus = status;
    },
    //导入按钮
    importExcel() {
      this.excelStatus = true;
      this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
        staffId: parent.vue.loginInfo.userinfo.id,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList && res.dataList.length > 0) {
            this.communityList = res.dataList;
          } else {
            this.$Message["error"]({
              background: true,
              content: "获取社区数据失败！",
            });
          }
        })
        .catch((err) => {
          this.$Message["error"]({
            background: true,
            content: "无法导入数据！",
          });
        });
    },
    //选择社区
    selectCommunity(code) {
      this.uploadData.orgCode = code;
      this.communityList.map((item, index) => {
        if (item.code == code) {
          this.uploadData.orgName = item.fullName;
        }
      });
    },

    //确认导入
    submitExcel() {
      // if (this.uploadData.orgCode == undefined) {
      //   this.$Message.error({
      //     background: true,
      //     content: "请选择所属社区",
      //   });
      //   return;
      // }
      if(this.excelFile == null){
        this.$Message.warning({
          background: true,
          content: "请先上传文件",
        })
        return
      }
      this.$Message.loading({
        content: "正在上传文件，请稍等...",
        duration: 0,
      });
      this.uploadData.orgCode = this.orgCode
      this.uploadData.orgName = this.orgName
      this.$refs.excelUpload.post(this.excelFile);
    },
    // 导入失败
    errorUpload() {
      this.$Message.destroy();
      this.$Notice.error({
        title: "导入失败",
        desc: "",
        duration: 0,
      });
    },
    //导入excel格式错误
    uploadFormatError(file) {
      this.$Message.destroy();
      this.$Notice.warning({
        title: "文件格式不正确",
        desc:
          "文件" +
          file.name +
          "格式不正确，请上传xls,xlsx,xlsb,xlsm或xlst格式的文件",
      });
    },
    //excel文件上传成功后执行
    myExcel(res) {
      this.$Message.destroy();
      if (res.code == 200 && res.dataList.length == 0) {
        this.$Message.success({
          background: true,
          content: "上传成功！",
        });
        this.excelStatus = false;
        this.hadlePageSize({
          pageSize: this.pageSize,
          page: this.pageNum,
          name: this.selectName,
          orgCode: this.orgCode,
        });
      } else if (res.code == 200 && res.dataList.length > 0) {
        this.errorContent = "";
        res.dataList.map((item, index) => {
          this.errorContent += `<div style="color:red">第${
            item.number + 2
          }行：${item.errorCode}</div>`;
        });
        this.$Notice.success({
          title: "数据导入失败，请排查以下问题后重新导入",
          desc: this.errorContent,
          duration: 0,
        });
      } else {
        this.$Notice.error({
          title: "导入失败",
          desc: res.desc,
          duration: 0,
        });
      }
    },
    //上传之前
    beforeExcelUpload(file) {
      this.action =
        window.vue.getProxy()["/syaa"].target +
        "/upload/sy/user/userOriginal/importUserOrignal";
      this.uploadData.oemCode = parent.vue.oemInfo.oemCode;
      this.excelFile = file;
      return false;
    },
    //菜单树
    getList(data) {
      // this.orgCode = data.communityCode;
      // this.streetCode = data.roadCode;
      // this.orgName = data.orgName;
      // if (data.regionType == "1") {
      //   this.region = data.projectCode;
      //   this.build = "";
      // }
      // if (data.regionType == "2" || data.regionType == "3") {
      //   this.build = data.projectCode;
      //   this.region = "";
      // }
      // this.regionType =
      //   data.regionType != undefined || data.regionType != null
      //     ? data.regionType
      //     : "";
      this.hadlePageSize({
        pageSize: this.pageSize,
        page: this.pageNum,
      });
    },
    // 搜索
    inputSearch(val) {  
      if(this.CascaderId.length != 0 && this.CascaderId.length != 3){
          this.$Message.warning({
            content:'请选择到社区进行查询',
            background:true
          })
          return 
      }
      if(this.CascaderId.length == 0) this.orgCode = ''
      this.hadlePageSize({
        pageSize: this.pageSize,
        page: this.pageNum,
        //菜单树社区编码
        orgCode: this.orgCode,
      });
      
    },
    //重置
    reset() {
      this.pageNum = 1;
      this.pageSize = 20;
      this.selectForm.name = '';
      this.selectForm.activeStatus = '0';
      this.selectForm.phone = '';
      this.selectForm.addr = '';
      this.selectForm.homeAddr = '';
      this.selectForm.activeTime = [];
      this.selectForm.syncHomeStatus = ''
      this.orgCode = ''
      this.orgName = ''
      this.CascaderId = []

      this.hadlePageSize({
        pageSize: this.pageSize,
        page: this.pageNum,
        //菜单树社区编码
        orgCode: this.orgCode,
      });
    },
    //分页
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/syaa/pc/sy/user/userOriginal/selectOriginalPage", {
        
        pageSize: obj.pageSize,
        page: obj.page,
        name: this.selectForm.name,
        activeStatus:
          this.selectForm.activeStatus == "0"
            ? ""
            : this.selectForm.activeStatus,
        phone: this.selectForm.phone,
        addr: this.selectForm.addr,
        homeAddr: this.selectForm.homeAddr,
         activeStatDate: this.selectForm.activeTime[0] ? this.$core.formatDate(new Date(this.selectForm.activeTime[0]),"yyyy-MM-dd") : null,
         activeEndDate: this.selectForm.activeTime[1] ? this.$core.formatDate(new Date(this.selectForm.activeTime[1]),"yyyy-MM-dd") : null,
        //菜单树社区编码
        orgCode: this.orgCode,
        regionType: this.regionType,
        streetCode: this.streetCode,
        region: this.region,
        build: this.build,
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
        syncHomeStatus:this.selectForm.syncHomeStatus,
        hasSensitive:this.hasSensitive
      })
        .then((res) => {
          if (res.code == 200 && res.dataList instanceof Array) {
            res.dataList.map((item, index, arr) => {
              arr[index].birthday = format(item.birthday, "yyyy-MM-dd");
              arr[index].sex =
                item.sex == "0" || item.sex == null
                  ? "未知"
                  : item.sex == "1"
                  ? "男"
                  : "女";
              arr[index].activeStatus =
                item.activeStatus == "1"
                  ? "未激活"
                  : item.activeStatus == "2"
                  ? "已激活"
                  : "";
            });
            this.total = res.maxCount;
            this.pageNum = res.currentPage;
            this.pageSize = res.pageSize;
            this.tableData = res.dataList;
            this.selectQuery = false;
             this.selectForm.name = '';
            // this.selectForm.activeStatus = '0';
            this.selectForm.phone = '';
            this.selectForm.addr = '';
            this.selectForm.homeAddr = '';
            this.selectForm.activeTime = [];
            // this.selectForm.syncHomeStatus = ''
          } else {
            this.$Message["error"]({
              background: true,
              content: "获取数据失败！",
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$Message["error"]({
            background: true,
            content: "获取数据失败！",
          });
          this.loading = false;
        });
    },
    changeEye(){
      this.hasSensitive = !this.hasSensitive
      this.hadlePageSize({
        pageSize: this.pageSize,
        page: this.pageNum,
        //菜单树社区编码
        orgCode: this.orgCode,
      }); 
    },
    // 获取级联
    getCascader(){
      this.$get('/orgzz/pc/organization/selectCascadeDataScope',{
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode:parent.vue.oemInfo.oemCode,
        orgCode:'4401',
        orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
      }).then(res=>{
         if(res.code == 200 && res.dataList.length != 0){
           this.CascaderList = this.forMatterTree(res.dataList)
           this.CascaderList.map(item =>{
             item.loading = false
           })
           if(this.isNum == 0 && this.CascaderList.length != 0){
             this.CascaderId.push(this.CascaderList[0].value)
           }
           this.hadlePageSize({
            pageSize: this.pageSize,
            page: this.pageNum,
          });
         }
      })
    },
    changeCasder(val,item){
      if(item.length == 1){
        // this.$Message.warning({
        //   content:'请选择到社区进行查询',
        //   background:true
        // })
        // return
      }else if(item.length == 2){
      //  this.$Message.warning({
      //     content:'请选择到社区进行查询',
      //     background:true
      //   })
      //   return
      }else if(item.length == 3){
        this.orgCode = item[2].orgCode
        this.orgName = item[2].orgName
      }
    },
    forMatterTree(data) {
      for (var i = 0; i < data.length; i++) {
        data[i].label = data[i].orgName;
        data[i].value = data[i].orgCode;
         if (data[i].children && data[i].children != null && data[i].children.length > 0 ) {
              this.forMatterTree(data[i].children);
         }
      }
      return data;
    },
    loadData (item, callback) {
      item.loading = true
      let params = {
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode:parent.vue.oemInfo.oemCode,
        orgCode:item.orgCode,
        orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
      };
       this.$get('/orgzz/pc/organization/selectCascadeDataScope',{
        ...params
      }).then(res=>{
         if(res.code == 200 && res.dataList.length != 0){
           let arr = this.forMatterTree(res.dataList)
           arr.map(items =>{
             if(items.orgLevel == 4){
               items.loading = false
             }
           })
           item.loading = false
           item.children = arr
           callback(item.children);
           if(this.isNum == 0 && item.children.length != 0){
             this.CascaderId.push(item.children[0].value)
             this.isNum = 1
             return
           }else if(this.isNum == 1 && item.children.length != 0){
             this.CascaderId.push(item.children[0].value)
             this.isNum = 0
             this.orgCode = item.children[0].value
             this.hadlePageSize({
              pageSize: this.pageSize,
              page: this.pageNum,
              //菜单树社区编码
              orgCode: this.orgCode,
            });
           }
         }else{
           callback([]);
         }
         console.log(this.CascaderList);
      })
    },
    // 点击发消息按钮
    openMessage(){
      // 验证机构是否开通短信功能
      this.$get('/syaa/pc/sy/sms/checkSmsChannel',{
          orgCode:parent.vue.loginInfo.userinfo.orgCode,
        }).then( res => {
          if(res.code == 200){
            this.messageStatus = true
            ++this.showNumber
            this.sendDisabled = false
            this.showMessage = true
          }
          if(res.code == 500){
              this.changeOpenTips(true)
              // this.$Message.warning({
              //   content:res.desc,
              //   background:true
              // })
          }
        })
      
    },
    // 发消息
    messageInput(status){
      this.messageStatus = status
    },
    // 弹窗返回按钮
    messageBack(){
      this.messageStatus = false
      this.showMessage = false
      
    },
    // 弹窗确认按钮
    messageSend(){
      if(this.$refs.message.selectList.length == 0){
        this.$Message.warning({
          content:'请选择接收人',
          background:true
        })
        return
      }
      if(this.$refs.message.formData.content == ''){
        this.$Message.warning({
          content:'请填写短信内容',
          background:true
        })
        return
      }
      let data = []
      this.$refs.message.selectList.map(item=>{
        data.push(item.id)
      })
      this.sendRow(data)
     
    },
    sendRow(data){
      // this.$post('/syaa/pc/sy/user/userOriginal/sendUserOriginalSms',{
      this.$post('/syaa/pc/sy/user/userOriginal/sendAllUserOriginalSms',{
        staffOrgCode:parent.vue.loginInfo.userinfo.orgCode,
        staffName:parent.vue.loginInfo.userinfo.realName,
        businessCode:'2',
        oemCode:parent.vue.oemInfo.oemCode,
        smsMsg:this.$refs.message.formData.content,
        ids:data.join(',')
      }).then(res=>{
        if(res.code == 200){
          this.messageStatus = false
          this.$Message.success({
            content:'短信发送成功',
            background:true
          })
        }else{
          this.$Message.error({
            content:res.desc,
            background:true
          })
          return
        }
      })
    },
    // 同步接口
    syncHome(){
      this.isDieabled = true
      this.$post('/syaa/pc/sy/user/userOriginal/syncOnlineHome',{
        ids:this.selectTableList.join(','),
        communityCode:this.CascaderId[this.CascaderId.length - 1 ]
      }).then(res=>{
        this.isDieabled = false
        if(res.code == 200){
          this.$Message.success({
            content:`导入同步成功${res.data}条`,
            background:true
          })
          // if(res.dataList && res.dataList.length != 0){
          //   let desc = ''
          //   res.dataList.map(item =>{
          //     desc += `<div>${item.username}${item.mobile || ''}${item.errorDesc}</div>`
          //   })
          //   this.$Notice.error({
          //     title: "以下数据同步失败",
          //     desc: desc,
          //     duration: 0
          //   });
          // }
          this.selectTableList = []
          this.hadlePageSize({
            pageSize: this.pageSize,
            page: this.pageNum,
          });
        }else{
          this.$Message.success({
            content:`导入同步成功0条`,
            background:true
          })
        }
      })
    }
  },
  components: {
    LiefengContent,
    LiefengTree,
    LiefengTable,
    LiefengModal,
    SelectUser,
    Message
  },
  created(){
    this.getCascader()
    this.orgName = parent.vue.loginInfo.userinfo.orgName
    let userButtonRoot = parent.vue.userButtonRoot;
    this.isDeleteAll = userButtonRoot.includes("USER_ORIGINAL_RESET") ? true : false
  }
};
</script>
    
<style scoped lang='less'>
#choose{
  /deep/.ivu-icon-ios-close {
    display: none;
  }
}
.modal {
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  .ivu-form-item {
    width: 45%;
  }
}
/deep/.ivu-upload-select {
  display: block;
  margin: 10px 20px;
  border: 1px dotted rgb(15, 121, 241);
  cursor: pointer;
}

.phone {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}

/deep/.ivu-drawer-body {
  .ivu-form-item {
    width: 100%;
     margin-bottom: 0;
  }
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}

.eye{
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin: 0 15px 0 ;
} 

</style>